import Link from "gatsby-link";
import React, { Component } from "react";
import { STATES } from "@upsolve/shared";
import { EVENTS } from "@upsolve/shared";
import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import * as SEARCH_TERM_TARGETS from "../components/analytics/searchTermTargets";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import P from "../components/Type/Paragraph.p";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import { H2 } from "../components/Type/Display.h";
import { HeroText } from "../components/Layout/Hero.div";

const contentGroup = CONTENT_GROUPS.LANDING_PAGE;
const templateProps = {
  pageComponentName: "LegalAidLocator",
  pageComponentVersion: 0,
};
const trackingPageContext = {
  contentGroup,
  contentLocalized: false,
  searchTermTargets: [SEARCH_TERM_TARGETS.BANKRUPTCY_LEGAL_AID],
  ...templateProps,
};

class LegalAidLocator extends Component {

  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }

  render() {
    return (
      <TopLevelLayout>
        <div className="index-container">
          <SEO
            title="Legal Aid Locator | Upsolve"
            description="Find a legal aid nonprofit in your state that can help you file for bankruptcy at no cost"
            image="https://upsolve.org/images/meta-legalaid.png"
            url="https://upsolve.org/legalaidlocator/"
          />
          <MainHeader trackingPageContext={trackingPageContext} />
          <main>
            <HeroText>
              <H2 as="h1">Legal Aid Locator</H2>
              <P>
                We have compiled a list of nonprofits that can help in every state. Make sure to give the legal aid
                nonprofit a call to see if you qualify for their help.
              </P>
              <div>
                <ul>
                  {Array.prototype.map.call(
                    Object.keys(STATES).filter(
                      (s) => ["AS", "FM", "GU", "MH", "MP", "PR", "PW", "VI"].includes(s) === false
                    ),
                    (state) => (
                      <li>
                        <Link to={`/${state.toLowerCase()}/legal-aid/`}>{STATES[state]}</Link>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </HeroText>
          </main>
          <MainFooter />
        </div>
      </TopLevelLayout>
    );
  }
}

export default LegalAidLocator;
